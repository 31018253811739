<template>
  <v-container>
    <RedeemPrizeDialog
      v-if="prizes[0]"
      :prizeSelectedToRedeem="prizeSelected"
      @prizeRedeemed="emitToContainerPrizeRedeemed"
    >
      <template #list-prizes="{ dialogEvents: { handleOpenDialog } }">
        <v-row justify="center">
          <v-col
            v-for="(prize, index) in prizes"
            :key="prize.id + index"
            cols="12"
            sm="10"
            md="6"
            lg="4"
            xl="3"
          >
            <ItemPrizesRedeem
              :prize="prize"
              class="pa-2"
              @showDialogRedeemPrize="
                (prizeSelected) => openDialog(prizeSelected, handleOpenDialog)
              "
            />
          </v-col>
        </v-row>
      </template>
    </RedeemPrizeDialog>
    <h3 v-else class="red--text text-center">
      {{ $t("prizesNotFound") }}
    </h3>
  </v-container>
</template>

<script>
export default {
  name: "ListPrizesComponent",
  components: {
    ItemPrizesRedeem: () => import("./ItemPrizesRedeem.vue"),
    RedeemPrizeDialog: () => import("./RedeemPrizeDialog.vue"),
  },
  props: {
    prizes: { type: Array, required: true },
  },
  data() {
    return {
      prizeSelected: {},
    };
  },
  methods: {
    openDialog(prizeSelected, handleEventOpenDialog) {
      this.prizeSelected = prizeSelected;
      handleEventOpenDialog();
    },
    emitToContainerPrizeRedeemed() {
      this.$emit("prizeRedeemed");
    },
  },
};
</script>
